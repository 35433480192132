<template>
    <div>
        <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
            <b-row>
                <b-col md="6">
                    <b-card no-body border-variant="primary">
                        <!-- <b-card-body> -->
                            <b-row class="m-1">
                                <b-col md="5">
                                    <b-form-group label="Conexión" label-for="idEmpresa" label-class="font-weight-bold">
                                        <v-select id="idEmpresa"                                        
                                            class="select-size-sm"
                                            v-model="filter.idEmpresa"
                                            :options="connections"
                                            :reduce="option => option.idEmpresa" 
                                            label="nombre" placeholder="Selecciona una opción"
                                            :clearable="false" :searchable="false"
                                            @input="selectConnection">
                                        </v-select>
                                    </b-form-group>
                                </b-col>

                                <b-col md="5">
                                    <b-form-group label="Lista Precios" label-for="precio_Empresa_Id" label-class="font-weight-bold">
                                        <v-select id="precio_Empresa_Id"
                                            class="select-size-sm"
                                            v-model="filter.precioEmpresaId"
                                            :options="pricesList"
                                            :reduce="option => option.precio_Empresa_Id" 
                                            label="nombre" placeholder="Selecciona una opción"
                                            :clearable="false" :searchable="false"
                                            @input="selectPriceList">
                                        </v-select>
                                    </b-form-group>
                                </b-col>

                                <b-col md="2" class="mt-2">
                                    <b-button size="sm" variant="primary" @click="getArticles">Buscar</b-button>
                                </b-col>
                            </b-row>
                        <!-- </b-card-body> -->
                    </b-card>
                </b-col>
                <b-col v-if="filter.idEmpresa && filter.precioEmpresaId && articles.length > 0" md="6">
                    <b-card no-body border-variant="warning">
                        <b-row class="m-1">
                            <b-col cols="12" md="5">
                                <b-form-group label="Conexión" label-for="idEmpresa2" label-class="font-weight-bold">
                                    <v-select id="idEmpresa2"                                        
                                        class="select-size-sm"
                                        v-model="filter2.idEmpresa"
                                        :options="connections.filter(item => item.idEmpresa !== filter.idEmpresa)"
                                        :reduce="option => option.idEmpresa" 
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false"
                                        @input="selectConnection2">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="5">
                                <b-form-group label="Lista Precios" label-for="precio_Empresa_Id2" label-class="font-weight-bold">
                                    <v-select id="precio_Empresa_Id2"
                                        class="select-size-sm"
                                        v-model="filter2.precioEmpresaId"
                                        :options="priceList2"
                                        :reduce="option => option.precio_Empresa_Id" 
                                        label="nombre" placeholder="Selecciona una opción"
                                        :clearable="false" :searchable="false"
                                        @input="selectPriceList2">
                                    </v-select>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="2" class="mt-2">
                                <b-button size="sm" variant="primary" @click="getArticles2">Buscar</b-button>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            
            <b-row>
                <b-col cols="12">
                    <b-card no-body class="mb-10">
                        <b-card-header v-if="articles.length" style="padding: 1rem 1rem;">
                            <table class="table_" style="width: 100%;">
                                <thead>
                                    <tr>
                                        <td colspan="5">
                                            <b-form-checkbox v-if="articles.length" v-model="noRelationated" class="custom-control-primary">
                                                Filtra no relacionados
                                            </b-form-checkbox>
                                        </td>
                                        <td>
                                            <b-form-checkbox v-if="articles2.length" v-model="noRelationated2" class="custom-control-primary">
                                                Filtra no relacionados
                                            </b-form-checkbox>
                                        </td>
                                        <td colspan="4" class="text-right">
                                            <b-button v-if="articles.length" size="sm" @click="exportToExcel" variant="secondary">
                                                <feather-icon icon="DownloadIcon" />
                                                Exportar a excel
                                            </b-button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="5" class="text-center_">
                                            <h5 class="text-uppercase">
                                                Lista de Articulos de {{ currentConnection ? currentConnection.nombre : '' }}
                                            </h5>
                                            <small><strong>Registros:</strong> {{ articles.length }}, </small>
                                            <small><strong>N:N:</strong> {{ firstNumNoRelationed }}, </small>
                                            <small><strong>N:R:</strong> {{ firstNumRelationated }}</small>
                                        </td>
                                        <td colspan="5" class="text-uppercase">
                                            <h5 v-if="articles2.length">
                                                Lista de Articulos de {{ secondaryConnections ? secondaryConnections.nombre : '' }}
                                            </h5>
                                            <small v-if="articles2.length"><strong>Registros:</strong> {{ articles2.length }}, </small>
                                            <small v-if="articles2.length"><strong>N:N:</strong> {{ firstNumNoRelationed2 }}, </small>
                                            <small v-if="articles2.length"><strong>N:R:</strong> {{ firstNumRelationated2 }}</small>
                                        </td>
                                    </tr>
                                </thead>
                            </table>
                        </b-card-header>
                        
                        <!-- Table Container -->
                        <div ref="tableContainer" class="mb-0_ table-responsive" :class="articles.length > 0 ? 'b-table-sticky-header' : ''" @scroll="handleScroll">
                            <table ref="refListTable" class="table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single table-small text-small small">
                                <thead>
                                    <tr>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('articulo_Id')">Articulo ID</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('clave_Articulo')">Clave Articulo</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('nombre')">Articulo</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('precio')" class="text-nowrap text-center">Lista Precio</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('ultimo_Costo')">Últ. Costo</th>                                        
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('articulo_Id2')">Articulo ID</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('clave_Articulo2')">Clave Articulo</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('nombre2')">Articulo</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('precio2')" class="text-nowrap text-center">Lista Precio</th>
                                        <th style="font-size: 10px; cursor: pointer;" @click="sort('ultimo_Costo2')" class="text-center">Últ. Costo</th>
                                    </tr>
                                </thead>
                                <tbody v-if="visibleItems.length > 0">
                                    <tr v-for="(articulo, index) in visibleItems" :key="index" @click="selectRow(articulo, index)"
                                        :class="{'selected-row': isSelected(index),}">
                                        <td style="font-size: 10px;" class="td-clave-articulo">{{ articulo.articulo_Id }}</td>
                                        <!-- <td style="font-size: 10px;" class="td-clave-articulo">{{ articulo.rel_Clave_Id }}</td> -->
                                        <td style="font-size: 10px;" class="td-clave-articulo">{{ articulo.clave_Articulo }}</td>
                                        <td style="font-size: 10px;" class="td-articulo text-wrap">{{ articulo.nombre }}</td>
                                        <td style="font-size: 10px;" class="td-precio-lista text-center">{{ articulo.precio }}</td>
                                        <td style="font-size: 10px;" class="td-ultimo-costo">{{ articulo.ultimo_Costo }}</td>                                        
                                        <td style="font-size: 10px;;" class="td-clave-articulo">{{ articulo.articulo_Id2 }}</td>
                                        <!-- <td style="font-size: 10px;" class="td-clave-articulo">{{ articulo.rel_Clave_Id2 }}</td> -->
                                        <td style="font-size: 10px;;" class="td-clave-articulo">{{ articulo.clave_Articulo2 }}</td>
                                        <td style="font-size: 10px;;" class="td-articulo text-wrap">{{ articulo.nombre2 }}</td>
                                        <td style="font-size: 10px;;" class="td-precio-lista text-center">{{ articulo.precio2 }}</td>
                                        <td style="font-size: 10px;;" class="text-center">{{ articulo.ultimo_Costo2 }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="10" class="text-center">
                                            <h6 class="mt-2">No hay registros</h6>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </b-card>
                </b-col>
            </b-row>

            <b-row v-if="articles.length > 0 && articles2.length > 0">
                <b-col cols="12">
                    <div class="demo-inline-spacing float-right buy-now">
                        <b-button v-if="isLinked" @click="unlinkedRelationShip" size="sm" variant="danger">
                            <feather-icon icon="Link2Icon" size="16" />
                            Desvincular
                        </b-button>

                        <b-button @click="isActiveModal = true" variant="primary">
                            <feather-icon icon="LinkIcon" size="16" />
                            Vincular
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-overlay>

        <!-- Modal  -->
        <b-modal ref="relationModal" id="relationModal" no-close-on-backdrop
            title-class="font-weight-bold" title="Vincular Articulo Por Clave Principal" hide-footer @cancel="isActiveModal = false" 
            @hidden="closeModal" :visible="isActiveModal" size="lg">
            <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
                <b-row>
                    <b-col cols="4">
                        <b-form-group label="Clave Articulo" :description="`DE ${currentConnection ? currentConnection.nombre : ''}`" label-for="article_Id" label-class="font-weight-bold">
                            <b-form-input
                                v-model="claveArticulo"
                                size="sm"
                                id="article_Id"
                                placeholder="Clave Articulo Origen"
                                @keydown.enter="addInCollection"/>
                        </b-form-group>                    
                    </b-col>

                    <b-col cols="4">
                        <b-form-group label="Clave Articulo" :description="`DE ${secondaryConnections ? secondaryConnections.nombre : ''}`" label-for="article_Id_2" label-class="font-weight-bold">
                            <b-form-input
                                v-model="claveArticulo2"
                                size="sm"
                                id="article_Id_2"
                                placeholder="Clave Articulo Destino"
                                @keydown.enter="addInCollection"/>
                        </b-form-group>                    
                    </b-col>

                    <b-col cols="4">
                        <b-button :disabled="loading || claveArticulo == '' || claveArticulo2 == ''" @click="addInCollection" size="sm" variant="primary" class="mt-2">
                            <feather-icon icon="PlusIcon" size="16" />
                            Agregar
                        </b-button>
                    </b-col>
                </b-row>

                
                <table class="table table-border table-hover b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single table-small text-small small">
                    <thead>
                        <tr>
                            <th style="font-size: 10px;" class="text-center" colspan="2">{{ currentConnection ? currentConnection.nombre : '' }}</th>
                            <th style="font-size: 10px;" class="text-center" colspan="2">{{ secondaryConnections ? secondaryConnections.nombre : '' }}</th>
                            <th></th>
                        </tr>
                        <tr>
                            <th style="font-size: 10px;">Clave</th>
                            <th style="font-size: 10px;">Articulo</th>
                            <th style="font-size: 10px;">Clave</th>
                            <th style="font-size: 10px;">Articulo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                            <td style="font-size: 10px">{{ item.clave_Matriz }}</td>
                            <td style="font-size: 10px">{{ item.articulo_Matriz }}</td>
                            <td style="font-size: 10px">{{ item.clave_Concretos }}</td>
                            <td style="font-size: 10px">{{ item.articulo_Concretos}}</td>
                            <td>
                                <b-button size="sm" variant="danger" @click="items.splice(index, 1)">
                                    <feather-icon icon="TrashIcon" size="16" />
                                </b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <b-row>
                    <b-col cols="12" class="float-rigth text-right">
                        <b-button :disabled="loading || items.length === 0" @click="saveRelations" size="sm" variant="primary" class="mt-2">
                            <feather-icon icon="SaveIcon" size="16" />
                            Guardar Relaciones
                        </b-button>
                    </b-col>
                </b-row>
                
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BCardFooter, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab,
} from 'bootstrap-vue'
import store from '@/store'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import vSelect from "vue-select";
import { RecycleScroller } from 'vue-virtual-scroller';
import * as XLSX from 'xlsx';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

import ArticlesProvider from '@/providers/Articles'
const ArticlesResource = new ArticlesProvider()

import PricesArticlesProvider from '@/providers/PricesArticles'
const PricesArticlesResource = new PricesArticlesProvider()

import ConnectionsProvider from '@/providers/Connections'
const ConnectionsResource = new ConnectionsProvider()

export default {
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BCardFooter,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BAvatar,
        BPagination,
        BFormSelect,
        BBadge,
        BCardSubTitle,
        BCardTitle,
        BTabs,
        BTab,

        //
        BCardActions,
        vSelect,
        RecycleScroller,
        FeatherIcon,
    },
    data() {
        return {
            pricesList: [],        // Lista de precios
            priceList2: [],        // Lista de precios de la conexión secundaria
            articles: [],          // Lista de artículos
            articles2: [],         // Lista de artículos de la conexión secundaria
            searchQuery: '',       // Consulta de búsqueda
            pageNumber: 1,         // Número de página actual
            pageSize: 1000,          // Tamaño de página
            loading: false,        // Indicador de carga
            items: [],             // Lista de artículos a vincular
            filter : {
                idEmpresa: null,
                precioEmpresaId: null,
            },

            filter2 : {
                idEmpresa: null,
                precioEmpresaId: null,
            },
            selectedRowIndex: -1,      // Índice seleccionado
            isActiveModal: false,      // Indicador de modal activo
            isLinked: false,
            relation: {},
            sortKey: '', // Clave para ordenar
            sortOrder: 1, // 1 para ascendente, -1 para descendente
            itemsPerPage: 500, // Número de artículos cargados por bloque
            currentPage: 1,
            visibleItems: [],
            claveArticulo: '',
            claveArticulo2: '',
            noRelationated: false,
            noRelationated2: false,
        }
    },
    computed: {
        currentConnectionId() {
            return store.getters['auth/getConexionId']
        },
        connections() {
            return store.getters['auth/getConnections'] //.filter(item => item.idEmpresa !== this.currentConnectionId)
        },
        currentConnection() {
            return store.getters['auth/getConnections'].find(item => item.idEmpresa === this.filter.idEmpresa)
        },
        secondaryConnections() {
            return store.getters['auth/getConnections'].find(item => item.idEmpresa == this.filter2.idEmpresa)
        },
       
        // Computed property para unificar los artículos de ambas empresas       
        articulosCombinados() {
            let combined = [];  

            // Mapeo de artículos de la empresa 1
            this.articles.forEach(articulo => {
                let articuloCombinado = {                    
                    
                    articulo_Id: articulo.articulo_Id,
                    clave_Articulo: articulo.clave_Articulo,
                    nombre: articulo.nombre,
                    precio: articulo.precio,
                    rel_Articulo_Id: articulo.rel_Articulo_Id,
                    rel_Clave_Id: articulo.rel_Clave_Id,
                    rel_Clave_Id_Concretos: articulo.rel_Clave_Id_Concretos,
                    rel_Clave_Id_Matriz: articulo.rel_Clave_Id_Matriz,
                    rel_Clave_Id_Orlo: articulo.rel_Clave_Id_Orlo,
                    ultimo_Costo: articulo.ultimo_Costo,

                    articulo_Id2: '',
                    clave_Articulo2: '',
                    nombre2: '',
                    precio2: '',
                    rel_Articulo_Id2: '',
                    rel_Clave_Id2: '',
                    rel_Clave_Id_Concretos2: '',
                    rel_Clave_Id_Matriz2: '',
                    rel_Clave_Id_Orlo2: '',
                    ultimo_Costo2: '',
                };
                combined.push(articuloCombinado);
            });

       

            // Mapeo de artículos de la empresa 2
            this.articles2.forEach(articulo => {

                // Buscar si el artículo de empresa 2 ya existe en la lista combinada por su relación Concretos
                // let existente = combined.find(item => item.rel_Clave_Id_Concretos === articulo.rel_Clave_Id_Concretos);
                let existente = combined.find(item => {
                    return item.rel_Clave_Id_Concretos && articulo.rel_Clave_Id_Concretos &&
                        item.rel_Clave_Id_Concretos === articulo.rel_Clave_Id_Concretos;
                });

                if (existente) {

                    // Si existe, completamos los campos de la empresa 2
                    existente.articulo_Id2 = articulo.articulo_Id;
                    existente.clave_Articulo2 = articulo.clave_Articulo;
                    existente.nombre2 = articulo.nombre;
                    existente.precio2 = articulo.precio;
                    existente.rel_Articulo_Id2 = articulo.rel_Articulo_Id;
                    existente.rel_Clave_Id2 = articulo.rel_Clave_Id;
                    existente.rel_Clave_Id_Concretos2 = articulo.rel_Clave_Id_Concretos;
                    existente.rel_Clave_Id_Matriz2 = articulo.rel_Clave_Id_Matriz;
                    existente.rel_Clave_Id_Orlo2 = articulo.rel_Clave_Id_Orlo;
                    existente.ultimo_Costo2 = articulo.ultimo_Costo;
                } else {
                    
                    // Si no existe, lo agregamos como un nuevo elemento combinado
                    let articuloCombinado = {
                        articulo_Id: '',
                        clave_Articulo: '',
                        nombre: '',
                        precio: '',
                        rel_Articulo_Id: '',
                        rel_Clave_Id: '',
                        rel_Clave_Id_Concretos: '',
                        rel_Clave_Id_Matriz: '',
                        rel_Clave_Id_Orlo: '',
                        ultimo_Costo: '',
                        articulo_Id2: articulo.articulo_Id,                        
                        clave_Articulo2: articulo.clave_Articulo,
                        nombre2: articulo.nombre,
                        precio2: articulo.precio,
                        rel_Articulo_Id2: articulo.rel_Articulo_Id,
                        rel_Clave_Id2: articulo.rel_Clave_Id,
                        rel_Clave_Id_Concretos2: articulo.rel_Clave_Id_Concretos,
                        rel_Clave_Id_Matriz2: articulo.rel_Clave_Id_Matriz,
                        rel_Clave_Id_Orlo2: articulo.rel_Clave_Id_Orlo,
                        ultimo_Costo2: articulo.ultimo_Costo,
                    };
                    combined.push(articuloCombinado);
                }
            });
            return combined;
        },
        _articulosCombinados() {
            let combined = [];

            // Mapeo de artículos de la empresa 1
            this.articles.forEach(articulo => {
                let articuloCombinado = {
                    articulo_Id1: articulo.articulo_Id,                    
                    claveArticulo1: articulo.clave_Articulo,
                    articulo1: articulo.nombre,
                    precioLista1: articulo.precio_Lista,
                    precio1: articulo.precio,
                    ultimoCosto1: articulo.ultimo_Costo,
                    relClaveId1: articulo.rel_Clave_Id, // Relación id
                    relClaveIdMatriz: articulo.rel_Clave_Id_Matriz, // Relación Matriz
                    relClaveIdConcretos1: articulo.rel_Clave_Id_Concretos, // Relación Concretos
                    relClaveIdOrlo1: articulo.rel_Clave_Id_Orlo, // Relación Orlo
                    articulo_Id2: '',
                    relClaveId2: '',
                    claveArticulo2: '',
                    articulo2: '',
                    precioLista2: '',
                    ultimoCosto2: '',
                    relClaveIdConcretos2: '' // Relación Concretos para empresa 2
                };
                combined.push(articuloCombinado);
            });

            // Mapeo de artículos de la empresa 2
            this.articles2.forEach(articulo => {
                // Buscar si el artículo de empresa 2 ya existe en la lista combinada por su relación Concretos
                let existente = combined.find(item => item.relClaveIdConcretos1 === articulo.rel_Clave_Id_Concretos);
                if (existente) {
                    // Si existe, completamos los campos de la empresa 2
                    existente.relClaveId2 = articulo.rel_Clave_Id;
                    existente.claveArticulo2 = articulo.clave_Articulo;
                    existente.articulo2 = articulo.nombre;
                    existente.articulo_Id2 = articulo.articulo_Id;
                    existente.precio2 = articulo.precio;
                    existente.precioLista2 = articulo.precio_Lista;
                    existente.ultimoCosto2 = articulo.ultimo_Costo;
                    existente.relClaveIdConcretos2 = articulo.rel_Clave_Id_Concretos;
                } else {
                    // Si no existe, lo agregamos como un nuevo elemento combinado
                    let articuloCombinado = {
                        relClaveId1: '',
                        claveArticulo1: '',
                        articulo1: '',
                        articulo_Id1: '',
                        precioLista1: '',
                        precio1: 0,
                        ultimoCosto1: '',
                        relClaveIdMatriz: '',
                        relClaveIdConcretos1: '',
                        relClaveIdOrlo1: '',
                        relClaveId2: articulo.rel_Clave_Id,
                        claveArticulo2: articulo.clave_Articulo,
                        articulo2: articulo.nombre,
                        articulo_Id2: articulo.articulo_Id,
                        precio2: articulo.precio,
                        precioLista2: articulo.precio_Lista,
                        ultimoCosto2: articulo.ultimo_Costo,
                        relClaveIdConcretos2: articulo.rel_Clave_Id_Concretos
                    };
                    combined.push(articuloCombinado);
                }
            });
            return combined;
        },
        sortedArticulosCombinados() {
            let data = this.articulosCombinados.slice().sort((a, b) => {
                if (!this.sortKey) return 0;
                
                const aValue = a[this.sortKey];
                const bValue = b[this.sortKey];

                // Si aValue es null y bValue no, ponemos aValue primero
                if (aValue === '' && bValue !== '') return -1;
                // Si bValue es null y aValue no, ponemos bValue primero
                if (bValue === '' && aValue !== '') return 1;

                // Si ambos son null, son iguales
                if (aValue === '' && bValue === '') return 0;

                // Orden normal cuando no hay null
                if (aValue < bValue) return -1 * this.sortOrder;
                if (aValue > bValue) return 1 * this.sortOrder;
                return 0;
            });

            // Aplicar los filtros
            if (this.noRelationated) {
                return data.filter(a => a.rel_Clave_Id === -1);
            } else if (this.noRelationated2) {
                return data.filter(a => a.rel_Clave_Id2 === -1);
            }

            return data;
        },
        firstNumNoRelationed() {
            return this.articles.filter(a => a.rel_Clave_Id === -1).length
        },
        firstNumNoRelationed2() {
            return this.articles2.filter(a => a.rel_Clave_Id === -1).length
        },
        firstNumRelationated() {
            return this.articles.filter(a => a.rel_Clave_Id > 0).length
        },
        firstNumRelationated2() {
            return this.articles2.filter(a => a.rel_Clave_Id > 0).length
        }

    },
    async mounted() {
        // const tableContainer = this.$refs.tableContainer;
        // tableContainer.addEventListener('scroll', this.handleScroll);
        // this.loadArticles(); // Cargar artículos al iniciar
        // await this.getPricesList()
        // await this.getConnections()
    },
    beforeDestroy() {
        const tableContainer = this.$refs.tableContainer;
        tableContainer.removeEventListener('scroll', this.handleScroll);
    },
    methods: { 
        async exportToExcel() {
            this.loading = true
            this.currentPage = 1
            this.visibleItems = this.sortedArticulosCombinados    
            
            await this.$nextTick()

            const table = this.$refs.refListTable;
            const wb = XLSX.utils.table_to_book(table);
            XLSX.writeFile(wb, 'Relaciones.xlsx');

            await this.$nextTick();

            const end = 1 * this.itemsPerPage;
            this.visibleItems = this.sortedArticulosCombinados.slice(0, end);

            this.loading = false
        },
        async getConnections () {
            this.loading = true
            const { data } = await ConnectionsResource.index()
            this.loading = false
        },
        async findByPrimaryKey() {
            try {
                this.loading = true
                const { data } = await ArticlesResource.findByPrimaryKey(this.relation.relClaveId)
                this.loading = false
                if (data.isSuccesful) {
                    this.relation = data.data
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        handleScroll2(event) {
            console.log(event)
            const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
                if (bottom) {
                    this.currentPage++;
                    this.loadItems();
                }
        },
        loadItems() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = this.currentPage * this.itemsPerPage;
            this.visibleItems = this.sortedArticulosCombinados.slice(0, end);
        },      
        async sort(key) {
            this.loading = true; // Mostrar el spinner de carga

            this.relation = {}
            this.selectedRowIndex = -1
            
            // Esperar a que la UI actualice el estado del spinner
            await this.$nextTick();
            this.sortOrder = 1;

            if (this.sortKey === key) {
                this.sortOrder = -this.sortOrder; // Cambiar la dirección del orden
            } else {
                this.sortKey = key; // Ordenar por una nueva columna
            }

            // Simular un retraso para la carga, puede eliminarse en un caso real
            await new Promise(resolve => setTimeout(resolve, 500));

            this.loading = false; // Ocultar el spinner
        },
        closeModal() {
            this.isActiveModal = false
        },
        selectRow(item, index) {
            this.relacion = {}
            this.selectedRowIndex = index
            this.relation = item

            console.log(this.relation)

            if (item && item.rel_Clave_Id && item.rel_Clave_Id != "" && item.rel_Clave_Id != 0 && item.rel_Clave_Id != null && item.rel_Clave_Id != -1) {
                this.isLinked = true
            } else {
                this.isLinked = false
            }
        },
        isSelected(index) {
            // Determina si la fila en el índice dado está seleccionada
            return index === this.selectedRowIndex
        },
        async selectConnection2(evt) {
            this.articles2 = []
            this.priceList2 = []
            this.selectedRowIndex = -1
            const idEmpresa = evt
            if (idEmpresa) {
                await this.getPricesList2(idEmpresa)
            }
        },
        async selectConnection(evt) {
            const idEmpresa = evt
            this.pricesList = []
            this.priceList2 = []
            this.articles = []
            this.articles2 = []
            this.pageNumber = 1
            this.selectedRowIndex = -1
            this.filter.precioEmpresaId = null
            this.filter2.idEmpresa = null
            this.filter2.precioEmpresaId = null
            if (idEmpresa) {
                await this.getPricesList(idEmpresa)
            }
        },
        selectPriceList(evt) {
            this.articles = []
            this.articles2 = []
            this.pageNumber = 1
            this.selectedRowIndex = -1
        },
        selectPriceList2() {
            this.articles2 = []
            this.pageNumber = 1
            this.selectedRowIndex = -1
        },
        async getPricesList(idEmpresa) {
            try {
                this.loading = true
                const { data } = await PricesArticlesResource.getAllPricesList(idEmpresa)
                this.loading = false                
                this.pricesList = data.data
            }catch(error) {
                this.loading = false
                console.error("Error loading prices list", error)
                this.handleErrorResponse(error)
            }
        },
        async getPricesList2(idEmpresa) {
            this.priceList2 = []
            try {
                this.loading = true
                const { data } = await PricesArticlesResource.getAllPricesList(idEmpresa)
                this.loading = false                
                this.priceList2 = data.data
            }catch(error) {
                this.loading = false
                console.error("Error loading prices list", error)
                this.handleErrorResponse(error)
            }
        },
        async getArticles() {
            this.selectedRowIndex = -1
            this.articles = []
            this.relation = {}
            const query = {
                ...this.filter,
                search: this.searchQuery,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize
            }

            try {
                this.loading = true
                const { data } = await ArticlesResource.getAllForSettingRelations(query)
                this.loading = false
                if (data.data.length === 0) {
                    this.endOfData = true
                    return
                } else {
                    this.articles.push(...data.data.map(a => {
                        return {
                            ...a,
                            rel_Clave_Id: a.rel_Clave_Id ? a.rel_Clave_Id : '',
                            rel_Clave_Id_Matriz: a.rel_Clave_Id_Matriz ? a.rel_Clave_Id_Matriz : '',
                            rel_Clave_Id_Concretos: a.rel_Clave_Id_Concretos ? a.rel_Clave_Id_Concretos : '',
                            rel_Clave_Id_Orlo: a.rel_Clave_Id_Orlo ? a.rel_Clave_Id_Orlo : ''
                        }
                    }));
                    this.pageNumber += 1;
                }
            }catch(error) {
                this.loading = false
                console.error("Error loading articles", error)
                this.handleErrorResponse(error)
            }
        },
        async getArticles2() {
            this.selectedRowIndex = -1
            this.articles2 = []
            this.relation = {}
            const query = {
                ...this.filter2,
                search: this.searchQuery,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize
            }

            try {
                this.loading = true
                const { data } = await ArticlesResource.getAllForSettingRelations(query)
                this.loading = false
                if (data.data.length === 0) {
                    this.endOfData = true
                    return
                } else {
                    this.articles2.push(...data.data.map(a => {
                        return {
                            ...a,
                            rel_Clave_Id: a.rel_Clave_Id ? a.rel_Clave_Id : '',
                            rel_Clave_Id_Matriz: a.rel_Clave_Id_Matriz ? a.rel_Clave_Id_Matriz : '',
                            rel_Clave_Id_Concretos: a.rel_Clave_Id_Concretos ? a.rel_Clave_Id_Concretos : '',
                            rel_Clave_Id_Orlo: a.rel_Clave_Id_Orlo ? a.rel_Clave_Id_Orlo : ''
                        }
                    }));
                    this.pageNumber += 1;
                }
            }catch(error) {
                this.loading = false
                console.error("Error loading articles", error)
                this.handleErrorResponse(error)
            }
        },
        async saveRelations() {
            if (this.items.length === 0) {
                this.warning('No hay relaciones a guardar.');
                return;
            }

            const payload = this.items.map(item => {
                return {
                    id: 0,
                    rel_Clave_Id_Matriz: item.rel_Clave_Id_Matriz,
                    rel_Clave_Id_Concretos: item.rel_Clave_Id_Concretos,
                    rel_Clave_Id_Orlo: item.rel_Clave_Id_Orlo
                }
            })

            this.loading = true
            try {
                // Hacer la llamada a la API para guardar las relaciones
                const { data } = await ArticlesResource.saveCustomRelation(payload)
                if (data.isSuccesful) {
                    this.success('Relaciones guardadas exitosamente.');
                    this.items = [];
                    this.isActiveModal = false;
                    this.articles = []
                    this.articles2 = []  
                    this.currentPage = 1
                    this.visibleItems = []
                    this.selectedRowIndex = -1
                    await this.getArticles()
                    await this.getArticles2()
                } else {
                    this.danger(data.message)
                }

            } catch (error) {
                this.loading = false
                console.error("Error saving relations", error);
                this.danger('Error al guardar las relaciones.');
            } finally {
                this.loading = false
            }
        },
        unlinkedRelationShip() {
            console.log(this.relation)
            this.$swal({
                title: `Desvincular relación`,
                html: `¿Estás seguro de que deseas desvincular la relación del artículo <strong>${this.relation.nombre}</strong>?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        this.loading = true
                        const { data } = await ArticlesResource.unlinkedRelationShip(1, this.relation.rel_Clave_Id)
                        this.loading = false
                        if (data.isSuccesful) {
                            this.selectedRowIndex = -1
                            this.isLinked = false
                            this.success(data.message)
                            this.articles2 = []
                            this.articles = []
                            await this.getArticles()
                            await this.getArticles2()
                        } else {
                            this.danger(data.message)
                        }
                    }catch(e) {
                        this.loading = false
                        this.handleResponseErrors(e)
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            })
        },
        focusNextInput(index, nextInputRef) {
            const nextInput = this.$refs[`${nextInputRef}${index}`][0];
            if (nextInput) {
                nextInput.focus();
                nextInput.select();
            }
        },
        focusNextButton(index) {
            const button = this.$refs[`actionButton${index}`][0]

            if (button) {                
                button.focus();

                const item = this.articles[index];  
                this.addInCollection(item, index);
                
                let newIndex = index + 1;
                if (newIndex >= this.articles.length) {
                    newIndex = 0;
                }

                // Esperar un breve momento para asegurarnos de que el foco se establezca en el botón
                this.$nextTick(() => {
                    this.focusNextInput(newIndex, 'relacionMatrizInput');
                });
            }
        },       
        async addInCollection() {

            if (!this.claveArticulo || this.claveArticulo.trim() === '') {
                this.warning('Debe ingresar una clave de artículo origen válida.');
                return;
            }

            if (!this.claveArticulo2 || this.claveArticulo2.trim() === '') {
                this.warning('Debe ingresar una clave de artículo destino válida.');
                return;
            }

            const idEmpresa = this.relation.clave_Articulo ? this.filter2.idEmpresa : this.filter.idEmpresa
            const claveArticulo = idEmpresa == 1 ? this.claveArticulo : this.claveArticulo2

            // Buscar el artículo que ya existe en la colección
            let articuloDuplicado = this.items.find(item => 
                item.clave_Matriz === this.claveArticulo || 
                item.clave_Concretos === this.claveArticulo2
            );

            // Si se encuentra el duplicado, mostramos el mensaje correspondiente
            if (articuloDuplicado) {
                if (articuloDuplicado.clave_Matriz === this.claveArticulo) {
                    this.danger(`El artículo con la clave "${this.claveArticulo}" ya está en la lista y no se puede volver a agregar.`);
                } else if (articuloDuplicado.clave_Concretos === this.claveArticulo2) {
                    this.danger(`El artículo con la clave "${this.claveArticulo2}" ya está en la lista y no se puede volver a agregar.`);
                }
                return;
            }

            const idEmpresa1 = this.filter.idEmpresa == 1 ? 1 : this.filter2.idEmpresa
            const idEmpresa2 = this.filter2.idEmpresa == 2 ? 2 : this.filter.idEmpresa
            const priceList1 = this.filter.idEmpresa == 1 ? this.filter.precioEmpresaId : this.filter2.precioEmpresaId

            const key1 = this.filter.idEmpresa == 1 ? this.claveArticulo : this.claveArticulo2
            const key2 = this.filter2.idEmpresa == 2 ? this.claveArticulo2 : this.claveArticulo
            const priceList2 = this.filter2.idEmpresa == 2 ? this.filter2.precioEmpresaId : this.filter.precioEmpresaId

            try {
                this.loading = true
                const { data } = await ArticlesResource.getArticlesByKeysAsync(idEmpresa1, key1, priceList1, idEmpresa2, key2, priceList2) //findByPrimaryKey(idEmpresa, claveArticulo)
                this.loading = false
                if (data.isSuccesful) {

                    const articles = data.data
                    const firstItem = articles[0] //.find(item => item.clave_Articulo === key1)
                    const secondItem = articles[1] //.find(item => item.clave_Articulo === key2)

                    //Ahora agregamos en los items
                    this.items.push({
                        id: 0,
                        clave_Matriz: firstItem.clave_Articulo,
                        articulo_Matriz: firstItem.nombre,
                        rel_Clave_Id_Matriz: firstItem.articulo_Id,

                        clave_Concretos: secondItem.clave_Articulo,
                        articulo_Concretos: secondItem.nombre,
                        rel_Clave_Id_Concretos: secondItem.articulo_Id,
                        rel_Clave_Id_Orlo: null,
                    })

                    this.$nextTick(() => {
                        this.relation = {}
                        this.selectedRowIndex = -1
                        this.claveArticulo = ''
                        this.claveArticulo2 = ''
                    })    
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }finally {
                this.loading = false
            }
        },
        removeFromCollection(index) {
            this.items.splice(index, 1)
        },
        handleScroll() {
            const tableContainer = this.$refs.tableContainer;
            const bottomOfTable = tableContainer.scrollTop + tableContainer.clientHeight >= tableContainer.scrollHeight - 10;
            if (bottomOfTable && !this.loading) {
                this.currentPage++;
                this.loadItems();
            }
        },
        onSearch() {
            this.pageNumber = 1; // Reiniciar el número de página a 1 cuando se realiza una búsqueda
            // this.loadArticles(); // Cargar artículos basados en la búsqueda
        }
    },
    watch:{
        noRelationated(value) {
            if (value) {
                this.currentPage = 1
                this.noRelationated2 = false; // Desactivar noRelationated2 si noRelationated está activo
            }
        },
        noRelationated2(value) {
            if (value) {
                this.currentPage = 1
                this.noRelationated = false; // Desactivar noRelationated si noRelationated2 está activo
            }
        },
        isActiveModal(value) {
            if (!value) {
                this.claveArticulo = ''
                this.claveArticulo2 = ''
                this.relation = {}
                this.selectedRowIndex = -1
            }
        },
        sortedArticulosCombinados() {
            this.loadItems();
        },
        relation(value) {
            if (value) {
                this.claveArticulo = value.clave_Articulo
                this.claveArticulo2 = value.clave_Articulo2
            }
        }
    }   
}
</script>

<!-- CSS personalizado -->
<style scoped>
    .td-clave-articulo {
        width: 120px; /* Ajusta el ancho de las columnas según sea necesario */
        white-space: wrap; /* Evita que el texto se envuelva en estas columnas */
    }

    .td-articulo {
        width: 200px; /* Ajusta el ancho de la columna Articulo según sea necesario */
    }

    /* Asegúrate de que el texto se envuelva en la columna Articulo */
    .text-wrap {
        word-wrap: break-word; /* Habilita el ajuste de texto en esta columna */
    }

    .table th, .table td {
        text-align: left; /* Alinea el texto a la izquierda */
    }

    .selected-row {
        background-color: #007bff; /* Color de fondo para la fila seleccionada */
        color: white; /* Color de texto para la fila seleccionada */
    }
</style>
<style>
.b-table-sticky-header {
    overflow-y: auto;
    min-height: 450px;
}

.table-wrapper {
  height: 600px; /* Ajusta la altura según tus necesidades */
  overflow-y: auto;
}

</style>